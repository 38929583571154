import React from "react"
import styled from "styled-components"
import HeroVideo from "../../components/landing-pages/Lp2/heroVideo"
import HeroImage from "../../components/landing-pages/Lp2/heroImage"
import Cta from "../../components/landing-pages/Lp2/cta"
import InfoCards from "../../components/landing-pages/Lp2/infoCards"
import AboutUsers from "../../components/landing-pages/Lp2/aboutUsers"
import TryPdfPro from "../../components/landing-pages/Lp2/tryPdfPro"
import Features from "../../components/landing-pages/Lp2/features"
import StickyCta from "../../components/landing-pages/stickyCta"
import StickyCtaClosable from "../../components/landing-pages/stickyCtaClosable"
import { useContext, useEffect } from "react"
import { GlobalAuthContext } from "../../Contexts/GlobalAuthContext"
import { useState } from "react"
import Seo from "../../components/seo"
import HeaderLp from "../../components/landing-pages/headerLp"
import saleDate from "../../utils/saleDate"

const Main = styled.div``

const Lp2 = ({ location, pageContext }) => {
  const [config, setConfig] = useState(undefined)
  console.log(pageContext)

  const { closedSticky, setClosedSticky } = useContext(GlobalAuthContext)

  useEffect(() => {
    if (closedSticky == null) {
      if (pageContext.node?.sticky_closable) setClosedSticky(false)
    }
  }, [])

  return (
    <Main>
      <Seo title="Create &amp; Edit PDF files with PDF Pro" noindex={true} />

      {closedSticky == false && (
        // <StickyCtaClosable date={pageContext.node.end_sale}></StickyCtaClosable>
        <StickyCtaClosable
          date={`Get 38% off for a limited time! Sale ends ${saleDate()}.`}
        ></StickyCtaClosable>
      )}

      <HeaderLp wrapLocation={location} closedSticky={closedSticky} />
      {pageContext.node?.sticky && (
        <StickyCta
          location={pageContext.location}
          closedSticky={closedSticky}
        ></StickyCta>
      )}

      {(pageContext.node?.hero === "box" || !pageContext.node) && (
        <HeroImage
          location={pageContext.location}
          conf={pageContext?.node}
        ></HeroImage>
      )}
      {pageContext.node?.hero === "video" && (
        <HeroVideo
          location={pageContext.location}
          conf={pageContext.node}
        ></HeroVideo>
      )}
      <InfoCards></InfoCards>
      <AboutUsers></AboutUsers>
      <TryPdfPro></TryPdfPro>
      <Features></Features>
      <Cta location={pageContext.location}></Cta>
    </Main>
  )
}

export default Lp2
