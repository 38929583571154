import React from "react"
import styled from "styled-components"
import Main from "../styles/mainStyle"
import MainWrapper from "../styles/mainWrapperStyle"
import background from "../../../images/Lp2/TryPDFpro.png"
import { TryButton } from "../styles/buttonStyle"
import TryPdf from "../../shared/TryPdf"
import { useState, useContext } from "react"
import { GlobalAuthContext } from "../../../Contexts/GlobalAuthContext"
import Window from "../../../icons/window.svg"

const TryMain = styled(Main)`
  overflow: hidden;
  margin: 0;
  width: 100%;
`

const TryWrapper = styled(MainWrapper)`
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: 1440px) {
    background-size: cover;
    width: 80%;
    min-width: 1440px;
  }

  @media (max-width: 768px) {
    background-size: 100% 100%;
  }

  @media (max-width: 475px) {
    background-size: 180% 100%;
  }

  padding: 9% 13%;
`
const Title = styled.h2`
  margin: 0;
  width: 100%;
  font-size: 64px;
  line-height: 64px;
  color: #ffffff;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    font-size: 46px;
    line-height: 46px;
    text-align: center;
    color: #ffffff;
  }

  @media (max-width: 575px) {
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
  }
`
const Paragraph = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;

  @media (max-width: 575px) {
    font-size: 14px;
    line-height: 17px;
  }
`

const TryPdfLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 32px;

  @media (max-width: 575px) {
    flex-direction: column;
  }
`

const Button = styled(TryButton)`
  padding: 16px 4.5%;

  @media (max-width: 575px) {
    padding: 16px 8%;
  }
`

const DownloadTrial = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #32c5ff;
  margin-left: 20px;
  @media (max-width: 575px) {
    margin-top: 32px;
    margin-left: 0;
  }
`
function TryPdfPro() {
  const { setScroll } = useContext(GlobalAuthContext)

  const [myPopUp, setMyPopUp] = useState("hide")
  //close popup function
  function closePopup() {
    setMyPopUp("hide")
    setScroll("hide")
  }
  //open popup function
  function openPopup(e) {
    e.preventDefault()
    setMyPopUp("show")
    setScroll("show")
  }

  return (
    <TryMain>
      <TryWrapper style={{ backgroundImage: `url(${background})` }}>
        <Title>Create, Edit, Secure, Review and Export to Word!</Title>
        <Paragraph>
          <b>PDF Pro</b> is the easiest solution to start working with 100%
          Adobe compatible PDF files. Whether you're looking to view, create,
          edit, secure, revise, or collaborate on a PDF document,{" "}
          <b>PDF Pro's</b> intuitive interface will allow you to take full
          advantage of using the Portable Document File format.
        </Paragraph>
        <Paragraph>
          The trial version of PDF Pro adds a watermark to documents saved while
          using PDF Pro. The trial will work for 15 days. You must purchase a
          license in order to activate the full version of PDF Pro.
        </Paragraph>
        <TryPdfLine>
          <Button onClick={openPopup}>
            Try PDF Pro <Window />
          </Button>
          <DownloadTrial>Download your trial version now!</DownloadTrial>
        </TryPdfLine>
      </TryWrapper>
      {myPopUp === "show" && <TryPdf close={closePopup} />}
    </TryMain>
  )
}

export default TryPdfPro
