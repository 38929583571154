import React from "react"
import Logo from "../../icons/PDF-logo-vector.svg"
import styled from "styled-components"
import { useRef } from "react"
import { useEffect } from "react"
import TryPdf from "../shared/TryPdf"
import { useState, useContext } from "react"
import { GlobalAuthContext } from "../../Contexts/GlobalAuthContext"
import { navigate } from "gatsby"

const HeaderS = styled.div`
  width: 80%;
  min-height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin: auto;
  max-width: 1440px;
  @media (max-width: 768px) {
    /* top: 300px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3%;
  }

  svg {
    cursor: pointer;

    @media (max-width: 768px) {
      margin-bottom: 3%;
    }
  }
`

const HeaderLimit = styled.div`
  width: 100%;
  background: white;
  position: fixed;
  ${props => (props.closedSticky == false ? `top: 41px;` : `top: 0;`)}
  /* top: 0; */

  z-index: 1000;
  opacity: 0;
  display: none;

  transition: opacity 0.5s;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3%;
  }
  border-bottom: 1px solid #e1e1e1;
`

const NavBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    font-size: 14px;
    line-height: 28px;
    text-decoration: none;
    color: #000000;

    display: flex;
  }
  width: 100%;
  color: white;

  @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      align-items: center:
  }
`

const Button = styled.button`
  background: ${props => props.bgColor};
  border-radius: 28px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
  border: none;
  width: 18%;
  height: 50px;
  margin-left: 5%;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 50%;

    margin: 0;
    margin-bottom: 3%;
  }
`
const Text = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #4e526b;
  @media (max-width: 768px) {
    margin-bottom: 3%;
    text-align: center;
  }
`
const ButtonHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
`

function StickyCta({ location, closedSticky }) {
  const { setScroll } = useContext(GlobalAuthContext)

  const [myPopUp, setMyPopUp] = useState("hide")
  const [url, setUrl] = useState("")

  //close popup function
  function closePopup() {
    setMyPopUp("hide")
    setScroll("hide")
  }
  //open popup function
  function openPopup(e) {
    e.preventDefault()
    setMyPopUp("show")
    setScroll("show")
  }

  const first = useRef(null)
  const ShowPoint = 500
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (140 < window.scrollY) {
        first.current.style.display = "flex"
      } else {
        first.current.style.display = "none"
      }
      if (ShowPoint < window.scrollY) {
        // first.current.style.display = "flex"
        // first.current.style.opacity = Math.min(
        //   1,
        //   Math.floor(((window.scrollY - ShowPoint) / 1000) * 10000) / 10000
        // )
        first.current.style.opacity = 1
      } else first.current.style.opacity = 0
    })
    if (window.location.href.includes("lp1")) setUrl("/lp1")
    if (window.location.href.includes("lp2")) setUrl("/lp2")
  }, [])

  return (
    <>
      <HeaderLimit ref={first} closedSticky={closedSticky}>
        <HeaderS>
          <Logo />
          <NavBar>
            <Text>Get the new version of PDF Pro today!</Text>
            <Button bgColor="#32C5FF" onClick={openPopup}>
              Try PDF Pro
            </Button>{" "}
            <Button
              bgColor="#00CD61"
              onClick={() => navigate(`/${location.split("/")[1]}/checkout`)}
            >
              Buy now
            </Button>
          </NavBar>
        </HeaderS>
      </HeaderLimit>
      {myPopUp === "show" && <TryPdf close={closePopup} />}
    </>
  )
}

export default StickyCta
