import React from "react"
import styled from "styled-components"
import { Card } from "../styles/cardStyle"
import {
  MainBulletPointsHolder,
  BulletPointHolder,
} from "../styles/bulletPointsStyle"
import Check from "../../../icons/Lp1/checkMark.svg"
import CreateConvert from "../../../icons/Lp2/createConvert.svg"
import EditSecure from "../../../icons/Lp2/editSecure.svg"
import ReadReview from "../../../icons/Lp2/readReview.svg"
import Main from "../styles/mainStyle"
import MainWrapper from "../styles/mainWrapperStyle"
import { CheckContainer } from "../styles/bulletPointsStyle"

const CardsMain = styled(Main)`
  background: #f4f7f6;
`
const CardsWrapper = styled(MainWrapper)`
  flex-direction: column;
`
const Title = styled.h2`
  margin: 0;
  font-size: 46px;
  line-height: 46px;
  text-align: center;
  color: #000000;
  width: 100%;

  @media (max-width: 375px) {
    line-height: 56px;
  }
`
const SubTitle = styled.h3`
  margin: 0;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  width: 100%;
`

const ICardHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;

  @media (max-width: 675px) {
    flex-direction: column;
  }
`
const ICard = styled(Card)`
  margin: 0;
  background: #ffffff;
  box-shadow: 38px 57px 62px -19px #e5eaf0;
  border-radius: 10px;
  width: 31%;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5%;

  @media (max-width: 675px) {
    width: 100%;
    margin-bottom: 16px;

    svg {
      margin: auto;
    }
  }
`
const ICardTitle = styled.h3`
  margin: 0;
  font-size: 25px;
  line-height: 29px;
  margin: 9% 0;

  @media (max-width: 675px) {
    width: 100%;
    text-align: center;
  }
`
const Mbph = styled(MainBulletPointsHolder)`
  @media (max-width: 675px) {
    margin: auto;
  }
`
const Bph = styled(BulletPointHolder)`
  margin-bottom: 6%;
`
const Point = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #4e526b;
`

const CheckHolder = styled(CheckContainer)`
  margin-top: 3px;
  width: 12px;

  .check {
    fill: #4e526b;
    width: 100%;
  }
`

function InfoCards() {
  return (
    <CardsMain>
      <CardsWrapper>
        <Title>PDF Pro</Title>
        <SubTitle>
          Create, Edit &amp; Review PDF files with the All-in-one PDF Pro
        </SubTitle>
        <ICardHolder>
          <ICard>
            <CreateConvert />
            <ICardTitle>Create &amp; Convert</ICardTitle>
            <Mbph>
              <Bph>
                <CheckHolder>
                  <Check />
                </CheckHolder>
                <Point>
                  Create PDF from <b>Word, Excel, PowerPoint</b>
                </Point>
              </Bph>
              <Bph>
                <CheckHolder>
                  <Check />
                </CheckHolder>
                <Point>Create PDF from any application that prints</Point>
              </Bph>
              <Bph>
                <CheckHolder>
                  <Check />
                </CheckHolder>
                <Point>Convert/Export PDF to Word</Point>
              </Bph>
              <Bph>
                <CheckHolder>
                  <Check />
                </CheckHolder>
                <Point>Always 100% industry standard PDF files</Point>
              </Bph>
            </Mbph>
          </ICard>
          <ICard>
            <EditSecure />
            <ICardTitle>Edit &amp; Secure</ICardTitle>
            <Mbph>
              <Bph>
                <CheckHolder>
                  <Check />
                </CheckHolder>
                <Point>
                  <b>Modify images:</b> Insert, delete, move, resize and rotate
                  graphics
                </Point>
              </Bph>
              <Bph>
                <CheckHolder>
                  <Check />
                </CheckHolder>
                <Point>
                  <b>Edit text:</b> Fix typos, change font size, style and color
                </Point>
              </Bph>
              <Bph>
                <CheckHolder>
                  <Check />
                </CheckHolder>
                <Point>
                  Enhance: Add watermarks, hyperlinks, attachments etc.
                </Point>
              </Bph>
              <Bph>
                <CheckHolder>
                  <Check />
                </CheckHolder>
                <Point>
                  Protect your content: <b>Apply passwords</b> & restrictions
                </Point>
              </Bph>
            </Mbph>
          </ICard>
          <ICard>
            <ReadReview />
            <ICardTitle>Read &amp; Review</ICardTitle>
            <Mbph>
              <Bph>
                <CheckHolder>
                  <Check />
                </CheckHolder>
                <Point>
                  Open & scroll any PDF file, e-book, or presentation
                </Point>
              </Bph>
              <Bph>
                <CheckHolder>
                  <Check />
                </CheckHolder>
                <Point>
                  Zoom and rotate pages within your PDF for optimal viewing
                </Point>
              </Bph>
              <Bph>
                <CheckHolder>
                  <Check />
                </CheckHolder>
                <Point>Highlight, use sticky notes & stamps</Point>
              </Bph>
              <Bph>
                <CheckHolder>
                  <Check />
                </CheckHolder>
                <Point>Attach any supporting file to your PDF</Point>
              </Bph>
            </Mbph>
          </ICard>
        </ICardHolder>
      </CardsWrapper>
    </CardsMain>
  )
}

export default InfoCards
