import React from "react"
import styled from "styled-components"
import Main from "../styles/mainStyle"
import MainWrapper from "../styles/mainWrapperStyle"
import Svg1 from "../../../icons/Lp2/svg1.svg"
import Svg2 from "../../../icons/Lp2/svg2.svg"
import Svg3 from "../../../icons/Lp2/svg3.svg"
import Svg4 from "../../../icons/Lp2/svg4.svg"
import Svg5 from "../../../icons/Lp2/svg5.svg"
import Svg6 from "../../../icons/Lp2/svg6.svg"

const FeaturesMain = styled(Main)`
  background: white;
`
const FeaturesWrapper = styled(MainWrapper)`
  flex-direction: column;
`

const Title = styled.h2`
  font-size: 64px;
  line-height: 64px;
  text-align: center;
  color: #000000;
  width: 100%;
`
const SubTitle = styled.h3`
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  width: 100%;
`

const Paragraph = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #4e526b;
`
const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
  width: 100%;
`
const Card = styled.div`
  width: 31%;
  min-height: 300px;
  min-width: 118px;
  margin-top: 4.3%;

  background-color: transparent;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */

  @media (max-width: 675px) {
    width: 48.5%;
    margin-top: 2.5%;
  }

  .card-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }

  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  /* Do an horizontal flip when you move the mouse over the flip box container */
  :hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  /* Position the front and back side */
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }

  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
    background-color: ${props => props.bgColor};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    border-radius: 10px;
    padding: 12.5%;
  }

  /* Style the back side */
  .flip-card-back {
    background-color: ${props => props.bgColor};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    border-radius: 10px;
    padding: 12.5%;
    transform: rotateY(180deg);
  }
`

const InCard = styled.div`
  width: 0%;
  height: 0%;
  flex-basis: 0;
  opacity: 0;
`

const CardText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin: 0;
`

const CardTitle = styled.h3`
  margin-top: 32px;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #ffffff;
`
function Features() {
  return (
    <FeaturesMain>
      <FeaturesWrapper>
        <Title>Loaded with features</Title>
        <SubTitle>
          Use PDF Pro's intuitive interface to grasp all of the flexibility and
          power of PDF.
        </SubTitle>
        <Paragraph>
          The all-new <b>PDF Pro</b> is trusted by over 30,000 customers to
          handle their PDF documents. Its refined collaborative tools will
          streamline the document review process and help you collect feedback
          efficiently.
          <b> PDF Pro</b> allows you to create and edit interactive PDF forms
          and share all your documents more securely.
        </Paragraph>
        <Cards>
          <Card bgColor={`#32C5FF`}>
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <Svg1 />
                <CardTitle>Create PDF from MS Office</CardTitle>
              </div>
              <div className="flip-card-back">
                <CardText>
                  Create PDF files straight from the toolbar of all your
                  favourite
                  <b> MS Office</b> applications like{" "}
                  <b> Word, Excel, PowerPoint</b> and
                  <b> Outlook</b>.
                </CardText>
              </div>
            </div>
          </Card>
          <Card bgColor={`#F8B300`}>
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <Svg2 />
                <CardTitle>Create PDF from any printable file</CardTitle>
              </div>
              <div className="flip-card-back">
                <CardText>
                  Create PDF files from <b>any application that prints</b> with
                  one click.
                </CardText>
              </div>
            </div>
          </Card>
          <Card bgColor={`#FF75CB`}>
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <Svg3 />
                <CardTitle>Powerful PDF Text Editor</CardTitle>
              </div>
              <div className="flip-card-back">
                <CardText>
                  <b>Add, remove,</b> and <b>edit</b> text and graphics within
                  all your PDF Files.
                </CardText>
              </div>
            </div>
          </Card>
          <Card bgColor={`#00D0CA`}>
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <Svg4 />
                <CardTitle>Secure PDF Files</CardTitle>
              </div>
              <div className="flip-card-back">
                <CardText>
                  <b>Apply passwords</b> to prevent unwanted viewers and
                  permissions to restrict functions like editing and printing.{" "}
                </CardText>
              </div>
            </div>
          </Card>
          <Card bgColor={`#00CD61`}>
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <Svg5 />
                <CardTitle>Reduce File Size</CardTitle>
              </div>
              <div className="flip-card-back">
                <CardText>
                  <b>Adjust the file size</b> of your PDF for easy email
                  distribution
                </CardText>
              </div>
            </div>
          </Card>
          <Card bgColor={`#FF593F`}>
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <Svg6 />
                <CardTitle>100% industry Standard</CardTitle>
              </div>
              <div className="flip-card-back">
                <CardText>
                  All PDF documents created with PDF Pro will be{" "}
                  <b>100% industry standard</b>. That means they will open with{" "}
                  <b>any PDF viewer</b> and on
                  <b> any operating system.</b>{" "}
                </CardText>
              </div>
            </div>
          </Card>
          {/* <Card bgColor={`#FF593F`} style={{ height: `0` }}>
            <InCard>
              <Svg6 />
              <CardText>
                All PDF documents created with PDF Pro will be{" "}
                <b>100% industry standard</b>. That means they will open with{" "}
                <b>any PDF viewer</b> and on
                <b> any operating system.</b>{" "}
              </CardText>
            </InCard>
          </Card> */}
        </Cards>
      </FeaturesWrapper>
    </FeaturesMain>
  )
}

export default Features
