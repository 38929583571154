import styled from "styled-components"

export const MainBulletPointsHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const BulletPointHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const CheckContainer = styled.div`
  width: 12px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-right: 8px;
  margin-top: 3px;
`
export const Bullet = styled.div``
export const Point = styled.div``
