import styled from "styled-components"

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 330px;
  max-width: 706px;
  height: auto;
  border-radius: 30px;
  padding-top: 64px;
  padding-left: 5%;
  padding-right: 5%;
  margin-left: auto;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    padding-top: 32px;
    min-height: 290px;
    /* flex-wrap: wrap; */
  }
  @media (max-width: 575px) {
    flex-wrap: wrap;
  }

  background: ${props => (props.bgColor ? `${props.bgColor}` : "gray")};
  .check {
    fill: ${props => (props.bulletColor ? `${props.bulletColor}` : "gray")};
    color: ${props => (props.bulletColor ? `${props.bulletColor}` : "gray")};
  }
`
