import React from "react"
import styled from "styled-components"
import Main from "../styles/mainStyle"
import MainWrapper from "../styles/mainWrapperStyle"
import { StaticImage } from "gatsby-plugin-image"

const CardsMain = styled(Main)`
  background: white;
`
const CardsWrapper = styled(MainWrapper)`
  flex-direction: column;
`

const Title = styled.h2`
  margin: 0;
  font-size: 46px;
  line-height: 46px;
  text-align: center;
  color: #000000;
  width: 100%;

  @media (max-width: 375px) {
    line-height: 56px;
  }
`
const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #4e526b;
  margin-top: 20px;
`

const Logos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 50px 0;

  img {
    width: 100%;
    height: auto;
  }
  div {
    margin: 0 1%;
  }

  @media (max-width: 675px) {
    flex-wrap: wrap;
    justify-content: space-around;
    div {
      margin-bottom: 24px;
    }
  }
`

const Paragraph = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  width: 100%;
  color: #4e526b;
  max-width: 666px;

  margin: auto;
`

function AboutUsers() {
  return (
    <CardsMain>
      <CardsWrapper>
        <Title>Use PDF Pro at the office</Title>
        <SubTitle>Trusted by over 30,000 customers including:</SubTitle>
        <Logos>
          <StaticImage
            src="../../../images/Lp2/ic_emerson.png"
            quality={60}
            loading="eager"
            alt="Emerson"
          />
          <StaticImage
            src="../../../images/Lp2/ic_versailles.png"
            quality={60}
            loading="eager"
            alt="Versailles"
          />
          <StaticImage
            src="../../../images/Lp2/ic_cisco.png"
            quality={60}
            loading="eager"
            alt="Cisco"
          />

          <StaticImage
            src="../../../images/Lp2/ic_microsoft.png"
            quality={60}
            loading="eager"
            alt="Microsoft"
          />
          <StaticImage
            src="../../../images/Lp2/ic_hp.png"
            quality={60}
            loading="eager"
            alt="Hp"
          />
          <StaticImage
            src="../../../images/Lp2/ic_sheraton.png"
            quality={60}
            loading="eager"
            alt="Sheraton"
          />
          <StaticImage
            src="../../../images/Lp2/ic_oracle.png"
            quality={60}
            loading="eager"
            alt="Oracle"
          />
          <StaticImage
            src="../../../images/Lp2/ic_mma.png"
            quality={60}
            loading="eager"
            alt="MMA"
          />
        </Logos>
        <Paragraph>
          Sold in over 90 countries, PDF Pro is trusted by small and large
          businesses alike around the world to handle their PDF document
          management needs. PDF Pro is available in 7 languages, and volume
          discounts are available.
        </Paragraph>
      </CardsWrapper>
    </CardsMain>
  )
}

export default AboutUsers
