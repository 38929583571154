import React from "react"
import styled from "styled-components"
import Main from "../styles/mainStyle"
import MainWrapper from "../styles/mainWrapperStyle"
import {
  MainBulletPointsHolder,
  BulletPointHolder,
} from "../styles/bulletPointsStyle"
import Check from "../../../icons/Lp1/checkMark.svg"
import { CheckContainer } from "../styles/bulletPointsStyle"
import { BuyButton } from "../styles/buttonStyle"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"
import { useEffect, useState } from "react"
import Window from "../../../icons/window.svg"
import Background from "../../../images/Lp2/bg-header.jpeg"

const HeroMain = styled(Main)`
  background: white;
  background-image: url(${Background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  @media (min-width: 2200px) {
    background-size: 100% 100%;
  }
`
const HeroWrapper = styled(MainWrapper)`
  background: #f4f7f6;
  /* background: rgba(244, 247, 246, 0.8); */
  border-radius: 20px;
  padding: 20px;
  flex-direction: column;
  margin-top: 1.5%;

  @media (min-width: 1440px) {
    padding: 2%;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 10%;
    padding-bottom: 0;
    border-radius: 0;
    margin: 0;
  }
`

const Bph = styled(BulletPointHolder)`
  margin: 8px 0;
  @media (max-width: 375px) {
    display: flex;
    justify-content: center;
  }
`
const CheckHolder = styled(CheckContainer)`
  margin-top: 8px;

  .check {
    fill: #ee2d28;
    fill: green;

    width: 100%;
  }
`
const Point = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;

  @media (min-width: 1400px) {
    font-size: 24px;
    line-height: 25px;
  }

  @media (max-width: 1100px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 25px;
  }
`

const Title = styled.h1`
  margin: 0;
  font-size: 60px;
  line-height: 60px;
  color: #000000;
  margin-bottom: 32px;
  text-align: center;
  margin-top: 32px;

  @media (max-width: 1254px) {
    font-size: 46px;
    line-height: 46px;
  }

  @media (max-width: 375px) {
    text-align: center;
  }
`

const TextPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 55%;
  @media (min-width: 1440px) {
    min-height: 404px;
    width: 50%;
  }

  @media (max-width: 1255px) {
    width: 50%;
  }
  @media (max-width: 1300px) {
    width: 100%;
  }

  @media (max-width: 1000px) {
    width: 60%;
  }

  @media (max-width: 768px) {
    /* order: 1; */
    width: 100%;
  }
`

const PontTitle = styled.h2`
  /* font-weight: 400; */
  font-size: 38px;
  line-height: 43px;
  margin: 25px 0;
  margin-top: 48px;

  color: #000000;

  @media (min-width: 1440px) {
    margin-top: 64px;
  }

  @media (max-width: 1300px) {
    margin-top: 32px;
  }
  @media (max-width: 950px) {
    margin-top: 32px;
  }
  @media (max-width: 768px) {
    margin-top: 32px;
  }
  @media (max-width: 1225px) {
    font-size: 32px;
    line-height: 37px;
  }
  @media (max-width: 375px) {
    text-align: center;
  }
`
const UpperPart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 10%;

  @media (max-width: 768px) {
    padding: 0%;

    flex-direction: column;
  }
`

const BottomPart = styled.div`
  width: 85%;
  margin: auto;
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    margin-top: 5%;
  }

  @media (max-width: 768px) and (min-width: 375px) {
    width: 125%;
    padding: 8% 12.5%;
    border-radius: 0;
    margin: 0;
    margin-top: 5%;
  }

  @media (max-width: 875px) {
    flex-direction: column;
  }
`
const BuyText = styled.h3`
  margin: 0;
  font-size: 25px;
  line-height: 27px;
  padding-right: 32px;
  color: #000000;
  display: inline;

  @media (min-width: 1630px) {
    .limited {
      display: inline;
    }
  }
  /* justify-content: row; */
  @media (max-width: 1254px) {
    font-size: 23px;
    line-height: 27px;
  }

  @media (max-width: 875px) {
    text-align: center;
    margin-bottom: 24px;
    padding-right: 0;
  }

  @media (max-width: 375px) {
    text-align: center;
  }

  @media (min-width: 1500px) {
    /* order: 1; */
  }
`

const Button = styled(BuyButton)`
  margin: 0;
  @media (max-width: 768px) {
    margin-top: 14px;
  }
`

const Mbph = styled(MainBulletPointsHolder)`
  /* @media (max-width: 768px) {
    display: flex;
    align-content: center;
    margin: auto;
  } */
`
const ButtonHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  max-width: 330px;

  @media (max-width: 875px) {
    align-items: center;
  }

  @media (min-width: 768px) and (max-width: 875px) {
    margin-top: 3%;
  }

  @media (min-width: 1500px) {
    /* order: 0; */
    align-items: flex-start;
  }
`
const ButtonUnderText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #4e526b;
  margin-top: 5%;
  font-family: Work Sans;
  text-align: right;

  @media (max-width: 768px) {
    text-align: center;
  }
`

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`
function HeroImage({ location, conf }) {
  const [lpType, setLpType] = useState("")

  useEffect(() => {
    if (window.location.href.includes("lp1")) setLpType("lp1")
    if (window.location.href.includes("lp2")) setLpType("lp2")
  }, [])
  return (
    <HeroMain>
      <HeroWrapper>
        <UpperPart>
          <Title>
            {conf?.h1 ? conf.h1 : `Create, Edit & Review PDF files`}
          </Title>
          <Row>
            <TextPart>
              <PontTitle>PDF Pro is the easiest solution to:</PontTitle>
              <Mbph>
                <Bph>
                  <CheckHolder>
                    <Check />
                  </CheckHolder>
                  <Point>
                    {conf?.HeroBullets_1
                      ? conf.HeroBullets_1
                      : `Create PDF from Word, Excel, PowerPoint`}
                  </Point>
                </Bph>
                <Bph>
                  <CheckHolder>
                    <Check />
                  </CheckHolder>
                  <Point>
                    {conf?.HeroBullets_2
                      ? conf.HeroBullets_2
                      : `Create PDF from any application that prints`}
                  </Point>
                </Bph>
                <Bph>
                  <CheckHolder>
                    <Check />
                  </CheckHolder>
                  <Point>
                    {conf?.HeroBullets_3
                      ? conf.HeroBullets_3
                      : `Convert/Export PDF to Word`}
                  </Point>
                </Bph>
              </Mbph>
            </TextPart>
            <StaticImage
              src="../../../images/Lp2/PdfPro.png"
              quality={60}
              loading="eager"
              alt="PDFPRO icon"
            />
          </Row>
        </UpperPart>
        <BottomPart>
          <BuyText
            dangerouslySetInnerHTML={{
              __html: conf?.h2
                ? conf.h2
                : `Get the new version of PDF Pro today!`,
            }}
          ></BuyText>
          {/* <ButtonHolder> */}
          <Button
            onClick={() => navigate(`/${location.split("/")[1]}/checkout`)}
          >
            Buy Now <Window />
          </Button>
          {/* <ButtonUnderText>
              Compatible with Windows 10/8/7/XP/Vista
            </ButtonUnderText>
          </ButtonHolder> */}
        </BottomPart>
      </HeroWrapper>
    </HeroMain>
  )
}

export default HeroImage
