import React from "react"
import styled from "styled-components"
import Main from "../styles/mainStyle"
import MainWrapper from "../styles/mainWrapperStyle"
import { BuyButton } from "../styles/buttonStyle"
import { navigate } from "gatsby"
import { useEffect, useState } from "react"
import Window from "../../../icons/window.svg"

const CtaMain = styled(Main)`
  background: #f4f7f6;
`

const CtaWrapper = styled(MainWrapper)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 575px) {
    flex-direction: column;
  }
`

const TextHolder = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 625px;
`
const Title = styled.h2`
  font-style: normal;
  font-size: 25px;
  line-height: 27px;
  color: #000000;
  @media (max-width: 575px) {
    text-align: center;
  }
`
const Text = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #4e526b;
  @media (max-width: 575px) {
    text-align: center;
  }
`

const Button = styled(BuyButton)`
  box-shadow: 0px 20px 12px -12px rgba(27, 223, 120, 0.316461);
  margin-top: 0;
  @media (max-width: 575px) {
    margin-top: 20px;
  }
`

const ButtonHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  @media (max-width: 575px) {
    align-items: center;
  }
`
const ButtonUnderText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #4e526b;
  margin-top: 4%;
  /* font-family: Work Sans; */
  text-align: right;

  @media (max-width: 768px) {
    margin-top: 10.2%;
  }

  @media (max-width: 575px) {
    text-align: center;
  }
`
function Cta({ location }) {
  const [lpType, setLpType] = useState("")

  useEffect(() => {
    if (window.location.href.includes("lp1")) setLpType("lp1")
    if (window.location.href.includes("lp2")) setLpType("lp2")
  }, [])
  return (
    <CtaMain>
      <CtaWrapper>
        <TextHolder>
          <Title>
            All PDF documents created with PDF Pro will be 100% industry
            standard.
          </Title>
          <Text>
            That means they will open with any PDF viewer and on any operating
            system.
          </Text>
        </TextHolder>
        <ButtonHolder>
          <Button
            onClick={() => navigate(`/${location.split("/")[1]}/checkout`)}
          >
            Buy Now <Window />
          </Button>
          <ButtonUnderText>
            Compatible with Windows 10/8/7/XP/Vista
          </ButtonUnderText>
        </ButtonHolder>
      </CtaWrapper>
    </CtaMain>
  )
}

export default Cta
